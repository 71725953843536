import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useForm, ValidationError } from "@formspree/react";
import Illustration from "images/login-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-lg mx-auto lg:max-w-none lg:mx-0`;
const TextColumn = tw(Column)`lg:w-6/12 mt-16 lg:mt-0`;
const IllustrationColumn = tw(Column)`lg:w-5/12 flex-shrink-0 relative`;

const TextContent = tw.div`text-center lg:text-left`;
const Subheading = tw.h5`text-primary-500`;
const Heading = tw.h2`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl text-gray-900 leading-tight`;
const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto lg:mx-0`;
const Input = tw.input`w-full px-4 py-3 mt-4 rounded-lg bg-gray-200 border border-gray-300 focus:border-primary-500 focus:outline-none focus:shadow-outline text-sm font-medium`;
const Textarea = tw.textarea`w-full h-24 px-4 py-3 mt-4 rounded-lg bg-gray-200 border border-gray-300 focus:border-primary-500 focus:outline-none focus:shadow-outline text-sm font-medium`;
const SubmitButton = tw.button`w-full mt-8 py-3 bg-primary-500 text-gray-100 rounded-lg hover:bg-primary-700 focus:outline-none focus:shadow-outline font-semibold transition duration-300`;

function ContactForm() {
  const [state, handleSubmit] = useForm("xkgnwgld");
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <Form onSubmit={handleSubmit}>
      <label htmlFor="name"></label>
      <Input id="name" type="text" name="name" placeholder="Your name" />
      <ValidationError prefix="Name" field="name" errors={state.errors} />

      <label htmlFor="email"></label>
      <Input
        id="email"
        type="email"
        name="email"
        placeholder="Your email address"
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />

      <label htmlFor="phone"></label>
      <Input
        id="phone"
        type="tel"
        name="phone"
        placeholder="Your phone number"
      />
      <ValidationError prefix="Phone" field="phone" errors={state.errors} />

      <label htmlFor="message"></label>
      <Textarea id="message" name="message" placeholder="Your message" />
      <ValidationError prefix="Message" field="message" errors={state.errors} />

      <SubmitButton type="submit" disabled={state.submitting}>
        Submit
      </SubmitButton>
    </Form>
  );
}

const TwoColContactUsWithIllustrationFullForm = ({
  subheading = "Contact Us",
  heading = "Feel free to get in touch with us.",
  description = "We would love to hear from you. Please fill out the form below and we will get in touch with you shortly.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "post",
  textOnLeft = true,
}) => {
  return (
    <Container>
      <TwoColumn>
        <TextColumn>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <ContactForm />
          </TextContent>
        </TextColumn>
        <IllustrationColumn>
          <img src={Illustration} alt="Contact Us Illustration" />
        </IllustrationColumn>
      </TwoColumn>
    </Container>
  );
};

export default TwoColContactUsWithIllustrationFullForm;
