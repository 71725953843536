import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import NooSooMain from "images/NooSoo/DSC00630 copy.jpg";
import SPF50 from "images/NooSoo/DSC00638 copy.jpg";
import { BorderBeam } from "@stianlarsen/border-beam";
import StatsIllustrationSrc from "images/NooSoo/DSC00671 copy.jpg";

export default () => {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = css`
    ${tw`rounded-4xl`}
    box-shadow: 5px 5px rgba(100, 21, 255, 0.4), 10px 10px rgba(100, 21, 255, 0.3), 15px 15px rgba(100, 21, 255, 0.2), 20px 20px rgba(100, 21, 255, 0.1), 25px 25px rgba(100, 21, 255, 0.05);
  `;
  return (
    // <div style={{ backgroundColor: "#DEDDD1", zIndex: "-1" }}>
    <AnimationRevealPage>
      <Hero
        heading={
          <>
            Effective & reliable <HighlightedText>NooSoo</HighlightedText>
          </>
        }
        description={
          <>
            <BorderBeam size={400} duration={6} />
            Noosoo is committed to providing high-quality sun protection for all
            skin types. <br />
            We offer two specialized products: SPF50 UVA/UVB Protection,
            designed to shield sensitive and dry skin from harmful sun exposure
            while maintaining hydration, <br />
            allowing you to enjoy the sun with confidence.
          </>
        }
        imageSrc={NooSooMain}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
        // watchVideoButtonText="Meet The Chefs"
      />
      <MainFeature
        // subheading={<Subheading>Established Since 2014</Subheading>}
        heading={
          <>
            We've been providing premium sun protection solutions
            <wbr /> <HighlightedText>over 5 years.</HighlightedText>
          </>
        }
        description={
          <Description>
            • 5 years of trusted sun protection expertise <br />
            • Specialized formulas for sensitive, dry, and oily skin <br />
            •SPF50 for hydration <br />
            •SPF50+ for lightweight, non-greasy coverage <br />
            • Maximum UVA/UVB protection <br />• Dermatologically tested for
            safety and quality
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={SPF50}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. 
            Please open the TabGrid component file to see the structure of the tabs props. */}
      <TabGrid
        heading={
          <div id="products">
            Checkout our <HighlightedText>Products</HighlightedText>
          </div>
        }
      />

      <MainFeature2
        // subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={
          <div id="choose-us1">
            Why <HighlightedText>Choose Us ?</HighlightedText>
          </div>
        }
        statistics={[
          {
            key: "Orders",
            value: "9400+",
          },
          {
            key: "Customers",
            value: "1800+",
          },
          {
            key: "Feedbacks ",
            value: "1500+",
          },
        ]}
        primaryButtonText="Order Now"
        primaryButtonUrl="https://order.now.com"
        imageInsideDiv={false}
        imageSrc={StatsIllustrationSrc}
        imageCss={Object.assign(imageCss)}
        imageContainerCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25 rounded`}
        textOnLeft={true}
      />
      <Footer />
    </AnimationRevealPage>
    // </div>
  );
};
